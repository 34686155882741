const logos: { [k: string]: string } = {
  default: 'logos/logo-leti-round.png',
  newYear: 'logos/logo-leti-round-new-year.png',
  easter: 'logos/logo-leti-round-easter.png',
  victoryDay: 'logos/logo-leti-round-9may.png',
  internationalWomensDay: 'logos/logo-leti-round-8.png',
  defenderFatherlandDay: 'logos/logo-leti-round-23.png',
  russiaDay: 'logos/logo-leti-round-russia.png',
  nationalUnityDay: 'logos/logo-leti-round-russia.png'
}

const rules: {
  [k: string]: { start: string[]; before: number; after: number }
} = {
  newYear: {
    start: ['01/01'],
    before: 7,
    after: 10
  },
  easter: {
    start: [
      '02/05/2021',
      '24/04/2022',
      '16/04/2023',
      '05/05/2024',
      '20/04/2025'
    ],
    before: 5,
    after: 0
  },
  victoryDay: {
    start: ['09/05'],
    before: 7,
    after: 0
  },
  defenderFatherlandDay: {
    start: ['23/02'],
    before: 3,
    after: 0
  },
  internationalWomensDay: {
    start: ['08/03'],
    before: 3,
    after: 0
  },
  russiaDay: {
    start: ['12/06'],
    before: 5,
    after: 0
  },
  nationalUnityDay: {
    start: ['04/11'],
    before: 5,
    after: 0
  }
}

export class LogoPicker {
  static getActualLogo() {
    for (const [holiday, rule] of Object.entries(rules)) {
      if (Array.isArray(rule.start)) {
        for (const date of rule.start) {
          if (LogoPicker.isDateInRange(date, rule.before, rule.after)) {
            return logos[holiday]
          }
        }
      } else {
        if (LogoPicker.isDateInRange(rule.start, rule.before, rule.after)) {
          return logos[holiday]
        }
      }
    }
    return logos.default
  }

  static isDateInRange(
    dateString: string,
    before: number,
    after: number
  ): boolean {
    const today: Date = new Date()
    const currentYear = today.getFullYear()

    const splitDate = dateString.split('/')
    const day = parseInt(splitDate[0])
    const month = parseInt(splitDate[1])
    const year = splitDate.length > 2 ? parseInt(splitDate[2]) : currentYear

    const holiday: Date = new Date(year, month - 1, day, 0, 0, 0, 0)
    const diffDays =
      (today.getTime() - holiday.getTime()) / (1000 * 60 * 60 * 24)

    return diffDays <= after && diffDays >= -before
  }

  static getDefaultLogo() {
    return logos.default
  }
}
