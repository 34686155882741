<template>
  <div>
    <div data-cy="LoadingSpinnerDiv" class="Loading__Div">
      <img
        v-show="loaded"
        :src="`${publicPath}${logo}`"
        class="LetiLogo"
        alt="Логотип ЛЭТИ"
        @load="onMainImageLoaded"
      />

      <div v-if="!loaded" class="loader" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { LogoPicker } from '@/utils/logoChanger'
import { ref } from 'vue'

const publicPath = import.meta.env.BASE_URL
const logo = LogoPicker.getActualLogo()

const loaded = ref(false)
function onMainImageLoaded() {
  loaded.value = true
}
</script>

<style lang="scss" scoped>
.Loading__Div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;

  .LetiLogo {
    width: 300px;
    height: 300px;
    animation: rotation 8s infinite linear;

    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(359deg);
      }
    }
  }

  .loader {
    box-sizing: border-box;

    border: 30px solid #f3f3f3;
    border-top: 30px solid #1e3799;
    border-radius: 50%;
    width: 275px;
    height: 275px;
    animation: spin 2s linear infinite;

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
</style>
