import Vue from 'vue'
import LoadingApp from '@/LoadingApp.vue'

async function bootstrap() {
  await import('@/modules/sentry')
  const [
    { default: App },
    { router },
    { createPinia },
    { useUserStore },
    { default: i18n }
  ] = await Promise.all([
    import('./App.vue'),
    import('./router'),
    import('pinia'),
    import('@/stores/user'),
    import('./i18n'),
    import('@/modules/plugins'),
    import('@/modules/fontawesome'),
    import('@/modules/ag-grid'),
    import('@/modules/components'),
    import('moment/dist/locale/ru')
  ])

  const pinia = createPinia()

  const userStore = useUserStore(pinia)
  await userStore.fetchCurrentUser()

  const app = new Vue({
    pinia,
    router,
    i18n,
    render: (h) => h(App)
  })

  app.$mount('#app')
}

new Vue({
  mounted: () => setTimeout(bootstrap),
  render: (h) => h(LoadingApp)
}).$mount('#app')
